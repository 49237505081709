*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
}

html,
body,
.App,
#root {
    // height: 100%;
    -webkit-font-smoothing: antialiased;
    width: 100%;
}

ul {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

@font-face {
    font-family: 'DM Sans';
    src: url('./assets/fonts/DMSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('./assets/fonts/DMSans-Medium.ttf');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'DM Sans';
    src: url('./assets/fonts/DMSans-Regular.ttf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('./assets/fonts/CerebriSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('./assets/fonts/cerebrisans-medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('./assets/fonts/CerebriSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url('./assets/fonts/CerebriSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Eina 03';
    src: url('./assets/fonts/eina03-regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Eina 03';
    src: url('./assets/fonts/eina03-semibold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Eina 03';
    src: url('./assets/fonts/eina03-bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'BR Firma';
    src: url('./assets/fonts/BRFirma-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'BR Firma';
    src: url('./assets/fonts/BRFirma-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'BR Firma';
    src: url('./assets/fonts/BRFirma-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BR Firma';
    src: url('./assets/fonts/BRFirma-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'BR Firma';
    src: url('./assets/fonts/BRFirma-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

a {
    text-decoration: none;
    cursor: pointer;
}
