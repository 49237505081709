@media (max-width: 319px) {
    .modalBackground {
        width: 100%;
        height: 100%;
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            background-color: rgba(145, 139, 139, 0.22);
            opacity: 15;
            margin: 0 auto;
    
            .modalcontainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                gap: 1rem;
                border-radius: 0.8rem;
                background-color: #ffffff;
                padding: 3.2rem;
                z-index: 0;
    
                .modaldescriptions {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                    gap: 2rem;
                    padding-top: 3.2rem;
    
                    .modalheading {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: 'DM Sans';
                        font-size: 2.4rem;
                        font-weight: 500;
                        line-height: 4rem;
                        color: #282830;
                        text-align: center;
                    }
    
                    .modalheadingdesc {
                        font-family: 'DM Sans';
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 2.4rem;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        width: 100%;
                    }
                }
    
                .modalform {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    // width: 50%;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
    
                    .inputgroup {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 0.4rem;
                        height: 6.8rem;
                        justify-content: space-between;
    
                        .inputlabel {
                            display: flex;
                            align-items: center;
                            height: 2.4rem;
                            font-family: 'DM Sans';
                            font-weight: 500;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                        }
    
                        .inputfield {
                            height: 4rem;
                            border: 0.4rem;
                            padding: 0.8rem 1.2rem;
                            display: flex;
                            align-items: center;
                            border: 0.1rem solid #E4E7EC;
                            background-color: #ffffff;
                            color: #475467;
                            font-weight: 400;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                            font-family: 'BR Firma';
    
                            &:focus {
                                outline: none;
                            }
                        }
                    }
    
                    .modalbtn {
                        width: 100%;
                        height: 2.4rem;
                        padding: 0.8rem 0;
                        outline: none;
                        border: none;
                        background-color: #5C59F3;
                        border-radius: 0.4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;
                        color: #ffffff;
                        font-family: 'DM Sans';
                        font-weight: 700;
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        text-align: center;
                    }
                }
    
            }
    
        }
}

@media (min-width: 320px) and (max-width: 480px) {
    .modalBackground {
            width: 100%;
            height: 100%;
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            background-color: rgba(145, 139, 139, 0.22);
            opacity: 15;
            margin: 0 auto;
    
            .modalcontainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                gap: 1rem;
                border-radius: 0.8rem;
                background-color: #ffffff;
                padding: 3.2rem;
                z-index: 0;
                width: 80%;
    
                .modaldescriptions {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                    gap: 2rem;
                    padding: 1.6rem 0;
    
                    .modalheading {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: 'DM Sans';
                        font-size: 2.4rem;
                        font-weight: 500;
                        line-height: 4rem;
                        color: #282830;
                        text-align: center;
                    }
    
                    .modalheadingdesc {
                        font-family: 'DM Sans';
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 2.4rem;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        width: 100%;
                    }
                }
    
                .modalform {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    // width: 50%;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
    
                    .inputgroup {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 0.4rem;
                        height: 6.8rem;
                        justify-content: space-between;
    
                        .inputlabel {
                            display: flex;
                            align-items: center;
                            height: 2.4rem;
                            font-family: 'DM Sans';
                            font-weight: 500;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                        }
    
                        .inputfield {
                            height: 4rem;
                            border: 0.4rem;
                            padding: 0.8rem 1.2rem;
                            display: flex;
                            align-items: center;
                            border: 0.1rem solid #E4E7EC;
                            background-color: #ffffff;
                            color: #475467;
                            font-weight: 400;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                            font-family: 'BR Firma';
    
                            &:focus {
                                outline: none;
                            }
                        }
                    }
    
                    .modalbtn {
                        width: 100%;
                        height: 2.4rem;
                        padding: 0.8rem 0;
                        outline: none;
                        border: none;
                        background-color: #5C59F3;
                        border-radius: 0.4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;
                        color: #ffffff;
                        font-family: 'DM Sans';
                        font-weight: 700;
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        text-align: center;
                    }
                }
    
            }
    
        }
}

@media (min-width: 481px) and (max-width: 720px) {
    .modalBackground {
            width: 100%;
            height: 100%;
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            background-color: rgba(145, 139, 139, 0.22);
            opacity: 15;
            margin: 0 auto;
    
            .modalcontainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                gap: 1rem;
                border-radius: 0.8rem;
                background-color: #ffffff;
                padding: 3.2rem;
                z-index: 0;
    
                .modaldescriptions {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                    gap: 2rem;
    
                    .modalheading {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: 'DM Sans';
                        font-size: 2.4rem;
                        font-weight: 500;
                        line-height: 4rem;
                        color: #282830;
                        text-align: center;
                    }
    
                    .modalheadingdesc {
                        font-family: 'DM Sans';
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 2.4rem;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        width: 50%;
                    }
                }
    
                .modalform {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    // width: 100%;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
    
                    .inputgroup {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 0.4rem;
                        height: 6.8rem;
                        justify-content: space-between;
    
                        .inputlabel {
                            display: flex;
                            align-items: center;
                            height: 2.4rem;
                            font-family: 'DM Sans';
                            font-weight: 500;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                        }
    
                        .inputfield {
                            height: 4rem;
                            border: 0.4rem;
                            padding: 0.8rem 1.2rem;
                            display: flex;
                            align-items: center;
                            border: 0.1rem solid #E4E7EC;
                            background-color: #ffffff;
                            color: #475467;
                            font-weight: 400;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                            font-family: 'BR Firma';
    
                            &:focus {
                                outline: none;
                            }
                        }
                    }
    
                    .modalbtn {
                        width: 100%;
                        height: 2.4rem;
                        padding: 0.8rem 0;
                        outline: none;
                        border: none;
                        background-color: #5C59F3;
                        border-radius: 0.4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;
                        color: #ffffff;
                        font-family: 'DM Sans';
                        font-weight: 700;
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        text-align: center;
                    }
                }
    
            }
    
        }
}

@media (min-width: 721px) and (max-width: 1039px) {
    .modalBackground {
            width: 100%;
            height: 100%;
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            background-color: rgba(145, 139, 139, 0.22);
            opacity: 15;
            margin: 0 auto;
    
            .modalcontainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                gap: 1rem;
                border-radius: 0.8rem;
                background-color: #ffffff;
                padding: 3.2rem;
                z-index: 0;
                width: 80%;
    
                .modaldescriptions {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                    gap: 2rem;
    
                    .modalheading {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: 'DM Sans';
                        font-size: 2.4rem;
                        font-weight: 500;
                        line-height: 4rem;
                        color: #282830;
                        text-align: center;
                    }
    
                    .modalheadingdesc {
                        font-family: 'DM Sans';
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 2.4rem;
                        text-align: center;
                        display: flex;
                        align-items: center;
                    }
                }
    
                .modalform {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
    
                    .inputgroup {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 0.4rem;
                        height: 6.8rem;
                        justify-content: space-between;
    
                        .inputlabel {
                            display: flex;
                            align-items: center;
                            height: 2.4rem;
                            font-family: 'DM Sans';
                            font-weight: 500;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                        }
    
                        .inputfield {
                            height: 4rem;
                            border: 0.4rem;
                            padding: 0.8rem 1.2rem;
                            display: flex;
                            align-items: center;
                            border: 0.1rem solid #E4E7EC;
                            background-color: #ffffff;
                            color: #475467;
                            font-weight: 400;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                            font-family: 'BR Firma';
    
                            &:focus {
                                outline: none;
                            }
                        }
                    }
    
                    .modalbtn {
                        width: 100%;
                        padding: 0.8rem 0;
                        outline: none;
                        border: none;
                        background-color: #5C59F3;
                        border-radius: 0.4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;
                        color: #ffffff;
                        font-family: 'DM Sans';
                        font-weight: 700;
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        text-align: center;
                    }
                }
    
            }
    
        }
}

@media (min-width: 1040px){
    .modalBackground {
            width: 100%;
            height: 100%;
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            background-color: rgba(145, 139, 139, 0.22);
            opacity: 15;
            margin: 0 auto;
    
            .modalcontainer {
                width: 59.2rem;
                height: 61.2rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                gap: 1rem;
                border-radius: 0.8rem;
                background-color: #ffffff;
                padding: 3.2rem;
                z-index: 0;
    
                .modaldescriptions {
                    margin-top: 3.2rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 41.7rem;
                    height: 10.8rem;
                    margin: 0 auto;
                    gap: 2rem;
    
                    .modalheading {
                        width: 39.17rem;
                        height: 4rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: 'DM Sans';
                        font-size: 3.2rem;
                        font-weight: 500;
                        line-height: 4rem;
                        color: #282830;
                        text-align: center;
                    }
    
                    .modalheadingdesc {
                        font-family: 'DM Sans';
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 2.4rem;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        width: 41.7rem;
                        height: 4.8rem;
                    }
                }
    
                .modalform {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    width: 53.3rem;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
    
                    .inputgroup {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 0.4rem;
                        height: 6.8rem;
                        justify-content: space-between;
    
                        .inputlabel {
                            display: flex;
                            align-items: center;
                            height: 2.4rem;
                            font-family: 'DM Sans';
                            font-weight: 500;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                        }
    
                        .inputfield {
                            height: 4rem;
                            border: 0.4rem;
                            padding: 0.8rem 1.2rem;
                            display: flex;
                            align-items: center;
                            border: 0.1rem solid #E4E7EC;
                            background-color: #ffffff;
                            color: #475467;
                            font-weight: 400;
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                            font-family: 'BR Firma';
    
                            &:focus {
                                outline: none;
                            }
                        }
                    }
    
                    .modalbtn {
                        width: 100%;
                        padding: 0.8rem 1rem;
                        outline: none;
                        border: none;
                        background-color: #5C59F3;
                        border-radius: 0.4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;
                        color: #ffffff;
                        font-family: 'DM Sans';
                        font-weight: 700;
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        text-align: center;
                        cursor: pointer;
                    }
                }
    
            }
    
        }
}
