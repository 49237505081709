@media (max-width: 319px) {
  .searchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    gap: 2rem;

    .nav {
      width: 100%;
      margin: 0 auto;

      .logoContainer {
        width: 100%;
        height: 100%;

        .svg {
          left: 0.04%;
          right: 91.46%;
          top: calc(50% - 30px / 2);
          display: none;
        }

        .avilogo {
          position: absolute;
          left: 15.85rem;
          top: -3.3rem;
          display: flex;
          align-items: center;
          display: none;

          .avititle {
            font-family: 'Eina 03';
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 2.4rem;
            color: #5c59f3;
          }
        }
      }

      .nav__links__box {
        width: 110rem;
        height: 5rem;
        display: flex;
        align-items: center;
        position: absolute;
        left: 22.39%;
        right: -1.61%;
        top: calc(50% - 50px / 2 - 14px);

        .nav__links {
          display: flex;
          align-items: center;
          width: 27.809rem;
          height: 100%;
          gap: 1.8rem;
          position: absolute;
          left: 57.784rem;
          top: 0;

          .abtbtn {
            width: 8.1rem;
            height: 4rem;
            border-radius: 1.6rem;
            border: none;
            outline: none;
            background-color: #ffffff;
            cursor: pointer;
            color: #464754;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            font-style: normal;
            text-align: center;
            display: flex;
            align-items: center;
          }

          .contactdiv {
            width: 17.909rem;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .contactlink {
              width: 15.509rem;
              height: 5rem;
              border-radius: 1.6rem;
              box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
              background-color: #ffffff;
              border: 1px solid #ecf1fc;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #5c59f3;
              font-family: 'Cerebri Sans';
              font-size: 1.8rem;
              font-weight: 500;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      .headertitle {
        font-family: 'DM Sans';
        font-weight: 700;
        font-size: 4.8rem;
        line-height: 5.5rem;
        text-align: center;
        color: #282830;

        .colored {
          color: #5c59f3;
        }
      }

      .headertext {
        font-family: 'DM Sans';
        font-weight: 400;
        color: #464754;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 2.4rem;
      }

      .dropdown__therapist {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;

        .wheredropdown {
          width: 90%;
          height: 3.8rem;
          border-radius: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffffff;
          border: 1px solid #e4e7ec;
          gap: 3.6rem;

          .dropdownwrapper {
            width: 100%;
            padding: 0.8rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dropdowntext {
              font-family: 'DM Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 1.4rem;
              line-height: 2.4rem;
              color: #344054;
            }

            .chevron {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }

        .findtherapist {
          width: 90%;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          background-color: #5c59f3;
          border-radius: 0.4rem;
          cursor: pointer;

          .findtherapist__text {
            font-family: 'DM Sans';
            color: #ffffff;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.4rem;
            text-align: center;
          }
        }
      }
    }

    .appointmentContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      justify-content: center;

      .scheduleAppointment {
        width: 100%;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f6f6f6;

        .therapistavi {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .therapistImage {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              scale: 0.75;
            }
          }

          .rectangle {
            background-color: #d9d9d9;
            border-radius: 1.6rem;
          }
        }

        .therapistdetails {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          gap: 0.4rem;

          .therapistName {
            width: 100%;
            height: 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: 'DM Sans';
            font-weight: 700;
            font-size: 2rem;
            line-height: 3.2rem;
            color: #343434;
          }

          .therapistTitle {
            width: 23.101rem;
            height: 2.8rem;
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.8rem;
            text-align: center;
            color: #838383;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .divbtn {
            width: 25.4rem;
            height: 6rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            gap: 1rem;

            .appointmentbtn {
              width: 23.4rem;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.8rem 1rem;
              gap: 1rem;
              border: none;
              outline: none;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }

    .footerdiv {
      margin: 5rem 0;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .searchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    gap: 2rem;

    .nav {
      width: 100%;
      margin: 0 auto;

      .logoContainer {
        width: 100%;
        height: 100%;

        .svg {
          left: 0.04%;
          right: 91.46%;
          top: calc(50% - 30px / 2);
          display: none;
        }

        .avilogo {
          position: absolute;
          left: 15.85rem;
          top: -3.3rem;
          display: flex;
          align-items: center;
          display: none;

          .avititle {
            font-family: 'Eina 03';
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 2.4rem;
            color: #5c59f3;
          }
        }
      }

      .nav__links__box {
        width: 110rem;
        height: 5rem;
        display: flex;
        align-items: center;
        position: absolute;
        left: 22.39%;
        right: -1.61%;
        top: calc(50% - 50px / 2 - 14px);

        .nav__links {
          display: flex;
          align-items: center;
          width: 27.809rem;
          height: 100%;
          gap: 1.8rem;
          position: absolute;
          left: 57.784rem;
          top: 0;

          .abtbtn {
            width: 8.1rem;
            height: 4rem;
            border-radius: 1.6rem;
            border: none;
            outline: none;
            background-color: #ffffff;
            cursor: pointer;
            color: #464754;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            font-style: normal;
            text-align: center;
            display: flex;
            align-items: center;
          }

          .contactdiv {
            width: 17.909rem;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .contactlink {
              width: 15.509rem;
              height: 5rem;
              border-radius: 1.6rem;
              box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
              background-color: #ffffff;
              border: 1px solid #ecf1fc;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #5c59f3;
              font-family: 'Cerebri Sans';
              font-size: 1.8rem;
              font-weight: 500;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }
    }

    .searchheader {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      .headertitle {
        font-family: 'DM Sans';
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 5.5rem;
        text-align: center;
        color: #282830;

        .colored {
          color: #5c59f3;
        }
      }

      .headertext {
        font-family: 'DM Sans';
        font-weight: 400;
        color: #464754;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 2.4rem;
        padding: 0 1rem;
      }

      .dropdown__therapist {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;

        .wheredropdown {
          width: 90%;
          height: 3.8rem;
          border-radius: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffffff;
          border: 1px solid #e4e7ec;
          gap: 3.6rem;

          .dropdownwrapper {
            width: 100%;
            padding: 0.8rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dropdowntext {
              font-family: 'DM Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 1.4rem;
              line-height: 2.4rem;
              color: #344054;
            }

            .chevron {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }

        .findtherapist {
          width: 90%;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          background-color: #5c59f3;
          border-radius: 0.4rem;
          cursor: pointer;

          .findtherapist__text {
            font-family: 'DM Sans';
            color: #ffffff;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.4rem;
            text-align: center;
          }
        }
      }
    }

    .appointmentContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      justify-content: center;
      align-items: center;

      .scheduleAppointment {
        width: 90%;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f6f6f6;
        margin: 0 auto;

        .therapistavi {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;

          .therapistImage {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              scale: 0.75;
            }
          }

          .rectangle {
            background-color: #d9d9d9;
            border-radius: 1.6rem;
          }
        }

        .therapistdetails {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          gap: 0.4rem;

          .therapistName {
            width: 100%;
            height: 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: 'DM Sans';
            font-weight: 700;
            font-size: 2rem;
            line-height: 3.2rem;
            color: #343434;
          }

          .therapistTitle {
            width: 23.101rem;
            height: 2.8rem;
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.8rem;
            text-align: center;
            color: #838383;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .divbtn {
            width: 25.4rem;
            height: 6rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            gap: 1rem;

            .appointmentbtn {
              width: 23.4rem;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.8rem 1rem;
              gap: 1rem;
              border: none;
              outline: none;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }

    .footerdiv {
      margin: 5rem 0;
    }
  }
}

@media (min-width: 481px) and (max-width: 720px) {
  .searchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    gap: 2rem;

    .nav {
      width: 100%;
      margin: 0 auto;

      .logoContainer {
        width: 100%;
        height: 100%;

        .svg {
          left: 0.04%;
          right: 91.46%;
          top: calc(50% - 30px / 2);
          display: none;
        }

        .avilogo {
          position: absolute;
          left: 15.85rem;
          top: -3.3rem;
          display: flex;
          align-items: center;
          display: none;

          .avititle {
            font-family: 'Eina 03';
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 2.4rem;
            color: #5c59f3;
          }
        }
      }

      .nav__links__box {
        width: 110rem;
        height: 5rem;
        display: flex;
        align-items: center;
        position: absolute;
        left: 22.39%;
        right: -1.61%;
        top: calc(50% - 50px / 2 - 14px);

        .nav__links {
          display: flex;
          align-items: center;
          width: 27.809rem;
          height: 100%;
          gap: 1.8rem;
          position: absolute;
          left: 57.784rem;
          top: 0;

          .abtbtn {
            width: 8.1rem;
            height: 4rem;
            border-radius: 1.6rem;
            border: none;
            outline: none;
            background-color: #ffffff;
            cursor: pointer;
            color: #464754;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            font-style: normal;
            text-align: center;
            display: flex;
            align-items: center;
          }

          .contactdiv {
            width: 17.909rem;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .contactlink {
              width: 15.509rem;
              height: 5rem;
              border-radius: 1.6rem;
              box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
              background-color: #ffffff;
              border: 1px solid #ecf1fc;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #5c59f3;
              font-family: 'Cerebri Sans';
              font-size: 1.8rem;
              font-weight: 500;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }
    }

    .searchheader {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      .headertitle {
        font-family: 'DM Sans';
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 5.5rem;
        text-align: center;
        color: #282830;

        .colored {
          color: #5c59f3;
        }
      }

      .headertext {
        font-family: 'DM Sans';
        font-weight: 400;
        color: #464754;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 2.4rem;
        padding: 0 1rem;
      }

      .dropdown__therapist {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;

        .wheredropdown {
          width: 90%;
          height: 3.8rem;
          border-radius: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffffff;
          border: 1px solid #e4e7ec;
          gap: 3.6rem;

          .dropdownwrapper {
            width: 100%;
            padding: 0.8rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dropdowntext {
              font-family: 'DM Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 1.4rem;
              line-height: 2.4rem;
              color: #344054;
            }

            .chevron {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }

        .findtherapist {
          width: 90%;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          background-color: #5c59f3;
          border-radius: 0.4rem;
          cursor: pointer;

          .findtherapist__text {
            font-family: 'DM Sans';
            color: #ffffff;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.4rem;
            text-align: center;
          }
        }
      }
    }

    .appointmentContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      justify-content: center;
      align-items: center;

      .scheduleAppointment {
        width: 90%;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f6f6f6;
        margin: 0 auto;

        .therapistavi {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;

          .therapistImage {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              scale: 0.75;
            }
          }

          .rectangle {
            background-color: #d9d9d9;
            border-radius: 1.6rem;
          }
        }

        .therapistdetails {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          gap: 0.4rem;

          .therapistName {
            width: 100%;
            height: 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: 'DM Sans';
            font-weight: 700;
            font-size: 2rem;
            line-height: 3.2rem;
            color: #343434;
          }

          .therapistTitle {
            width: 23.101rem;
            height: 2.8rem;
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.8rem;
            text-align: center;
            color: #838383;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .divbtn {
            width: 25.4rem;
            height: 6rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            gap: 1rem;

            .appointmentbtn {
              width: 23.4rem;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.8rem 1rem;
              gap: 1rem;
              border: none;
              outline: none;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }

    .footerdiv {
      margin: 5rem 0;
    }
  }
}

@media (min-width: 721px) and (max-width: 1039px) {
  .searchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    gap: 2rem;

    .nav {
      width: 100%;
      margin: 0 auto;

      .logoContainer {
        width: 100%;
        height: 100%;

        .svg {
          left: 0.04%;
          right: 91.46%;
          top: calc(50% - 30px / 2);
          display: none;
        }

        .avilogo {
          position: absolute;
          left: 15.85rem;
          top: -3.3rem;
          display: flex;
          align-items: center;
          display: none;

          .avititle {
            font-family: 'Eina 03';
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 2.4rem;
            color: #5c59f3;
          }
        }
      }

      .nav__links__box {
        width: 110rem;
        height: 5rem;
        display: flex;
        align-items: center;
        position: absolute;
        left: 22.39%;
        right: -1.61%;
        top: calc(50% - 50px / 2 - 14px);

        .nav__links {
          display: flex;
          align-items: center;
          width: 27.809rem;
          height: 100%;
          gap: 1.8rem;
          position: absolute;
          left: 57.784rem;
          top: 0;

          .abtbtn {
            width: 8.1rem;
            height: 4rem;
            border-radius: 1.6rem;
            border: none;
            outline: none;
            background-color: #ffffff;
            cursor: pointer;
            color: #464754;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            font-style: normal;
            text-align: center;
            display: flex;
            align-items: center;
          }

          .contactdiv {
            width: 17.909rem;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .contactlink {
              width: 15.509rem;
              height: 5rem;
              border-radius: 1.6rem;
              box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
              background-color: #ffffff;
              border: 1px solid #ecf1fc;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #5c59f3;
              font-family: 'Cerebri Sans';
              font-size: 1.8rem;
              font-weight: 500;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }
    }

    .searchheader {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      width: 50%;

      margin: 0 auto;

      .headertitle {
        font-family: 'DM Sans';
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 5.5rem;
        text-align: center;
        color: #282830;

        .colored {
          color: #5c59f3;
        }
      }

      .headertext {
        font-family: 'DM Sans';
        font-weight: 400;
        color: #464754;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 2.4rem;
        padding: 0 1rem;
      }

      .dropdown__therapist {
        // width: 50%;
        width: 100%;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;

        .wheredropdown {
          width: 100%;
          // width: 90%;
          height: 3.8rem;
          border-radius: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffffff;
          border: 1px solid #e4e7ec;
          gap: 3.6rem;

          .dropdownwrapper {
            width: 100%;
            padding: 0.8rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dropdowntext {
              font-family: 'DM Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 1.4rem;
              line-height: 2.4rem;
              color: #344054;
            }

            .chevron {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }

        .findtherapist {
          width: 60%;
          // width: 90%;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          background-color: #5c59f3;
          border-radius: 0.4rem;
          cursor: pointer;

          .findtherapist__text {
            font-family: 'DM Sans';
            color: #ffffff;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.4rem;
            text-align: center;
          }
        }
      }
    }

    .appointmentContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      justify-content: center;
      align-items: center;

      .scheduleAppointment {
        // width: 90%;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f6f6f6;
        margin: 0 auto;

        .therapistavi {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;

          .therapistImage {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              // scale: 0.75;
              max-width: 100%;
            }
          }

          .rectangle {
            background-color: #d9d9d9;
            border-radius: 1.6rem;
          }
        }

        .therapistdetails {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          gap: 0.4rem;

          .therapistName {
            width: 100%;
            height: 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: 'DM Sans';
            font-weight: 700;
            font-size: 2rem;
            line-height: 3.2rem;
            color: #343434;
          }

          .therapistTitle {
            width: 23.101rem;
            height: 2.8rem;
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.8rem;
            text-align: center;
            color: #838383;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .divbtn {
            width: 25.4rem;
            height: 6rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            gap: 1rem;

            .appointmentbtn {
              width: 23.4rem;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.8rem 1rem;
              gap: 1rem;
              border: none;
              outline: none;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }

    .footerdiv {
      margin: 5rem 0;
    }
  }
}

@media (min-width: 1040px) {
  .searchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;

    .searchheader {
      width: 91.2rem;
      height: 24.9rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      margin: 0 auto;
      padding-top: 3.3rem;

      .headertitle {
        width: 58.1rem;
        height: 12.3rem;
        font-family: 'DM Sans';
        font-weight: 700;
        font-size: 4.8rem;
        line-height: 5.5rem;
        text-align: center;
        color: #282830;

        .colored {
          color: #5c59f3;
        }
      }

      .headertext {
        width: 54.5rem;
        height: 4.6rem;
        font-family: 'DM Sans';
        font-weight: 400;
        color: #464754;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 2.4rem;
      }

      .dropdown__therapist {
        width: 91rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;

        .wheredropdown {
          width: 23.3rem;
          height: 3.8rem;
          border-radius: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffffff;
          border: 1px solid #e4e7ec;
          gap: 3.6rem;

          .dropdownwrapper {
            width: 100%;
            padding: 0.8rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dropdowntext {
              font-family: 'DM Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 1.4rem;
              line-height: 2.4rem;
              color: #344054;
            }

            .chevron {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }

        .findtherapist {
          width: 14.4rem;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          background-color: #5c59f3;
          border-radius: 0.4rem;
          cursor: pointer;

          .findtherapist__text {
            font-family: 'DM Sans';
            color: #ffffff;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.4rem;
            text-align: center;
          }
        }
      }
    }

    .appointmentContainer {
      width: 123.704rem;
      height: 65.3rem;
      padding: 1rem;
      display: flex;
      align-items: flex-start;
      gap: 2.4rem;
      // justify-content: center;
      margin: 8rem auto 0 auto;

      .scheduleAppointment {
        width: 38.968rem;
        height: 63.3rem;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2.4rem;
        padding: 2.4rem;
        background-color: #f6f6f6;

        .therapistavi {
          width: 34.168rem;
          height: 43.3rem;
          position: relative;

          .therapistpics {
            object-fit: cover;
          }

          .therapistImage {
            position: absolute;
            width: 37.241rem;
            height: 51.04rem;
            left: 0.433rem;
          }

          .rectangle {
            position: absolute;
            width: 34.168rem;
            height: 43.3rem;
            background-color: #d9d9d9;
            border-radius: 1.6rem;
            left: 0.4rem;
            top: 0.2rem;
          }
        }

        .therapistdetails {
          width: 27.864rem;
          height: 2.8rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          gap: 0.4rem;

          .therapistName {
            width: 100%;
            height: 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: 'DM Sans';
            font-weight: 700;
            font-size: 2rem;
            line-height: 3.2rem;
            color: #343434;
          }

          .therapistTitle {
            color: #464754;
            text-align: center;
            font-family: DM Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          .divbtn {
            width: 25.4rem;
            height: 6rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            gap: 1rem;

            .appointmentbtn {
              width: 23.4rem;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.8rem 1rem;
              gap: 1rem;
              border: none;
              outline: none;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }

    .footerdiv {
      margin: 5rem 0;
    }
  }
}
