@media (max-width: 319px) {
    .footer {
            width: 100%;
            margin: 0 auto;
    
            .footerwrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                flex: none;
                order: 4;
                flex-grow: 0;
    
                .avi__logo {
                    padding: 2.3rem 0;
                    color: #5C59F3;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                    display: flex;
                    align-items: center;
                    font-family: 'Eina 03';
                    font-weight: 700;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                }
    
                .footer__links {
                    padding: 2.4rem 0;
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    align-items: center;
                    justify-content: center;
    
                    .footer__btn {
                        height: 2.2rem;
                        color: #838383;
                        font-family: 'DM Sans';
                        font-weight: 400;
                        font-size: 1.8rem;
                        display: flex;
                        align-items: center;
                        border: none;
                        outline: none;
                        padding-right: 1.5rem;
                        background-color: transparent;
                        cursor: pointer;
                    }
                }
            }
        }
}

@media (min-width: 320px) and (max-width: 480px) {
    .footer {
            width: 100%;
            margin: 0 auto;
    
            .footerwrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                flex: none;
                order: 4;
                flex-grow: 0;
    
                .avi__logo {
                    padding: 2.3rem 0;
                    color: #5C59F3;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                    display: flex;
                    align-items: center;
                    font-family: 'Eina 03';
                    font-weight: 700;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                }
    
                .footer__links {
                    padding: 2.4rem 0;
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    align-items: center;
                    justify-content: center;
    
                    .footer__btn {
                        height: 2.2rem;
                        color: #838383;
                        font-family: 'DM Sans';
                        font-weight: 400;
                        font-size: 1.8rem;
                        display: flex;
                        align-items: center;
                        border: none;
                        outline: none;
                        padding-right: 1.5rem;
                        background-color: transparent;
                        cursor: pointer;
                    }
                }
            }
        }
}

@media (min-width: 481px) and (max-width: 720px) {
    .footer {
            width: 100%;
            height: 6.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
    
            .footerwrapper {
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: none;
                order: 4;
                flex-grow: 0;
    
                .avi__logo {
                    padding: 2.3rem 0;
                    color: #5C59F3;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                    display: flex;
                    align-items: center;
                    font-family: 'Eina 03';
                    font-weight: 700;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                }
    
                .footer__links {
                    padding: 2.4rem 0;
                    display: flex;
                    gap: 2rem;
                    align-items: flex-start;
    
                    .footer__btn {
                        height: 2.2rem;
                        color: #838383;
                        font-family: 'DM Sans';
                        font-weight: 400;
                        font-size: 1.8rem;
                        // line-height: 2.8rem;
                        display: flex;
                        align-items: center;
                        border: none;
                        outline: none;
                        padding-right: 1.5rem;
                        background-color: transparent;
                        cursor: pointer;
                    }
                }
            }
        }
}

@media (min-width: 721px) and (max-width: 1039px) {
    .footer {
            width: 100%;
            height: 6.8rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
    
            .footerwrapper {
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: none;
                order: 4;
                flex-grow: 0;
    
                .avi__logo {
                    padding: 2.3rem 0;
                    color: #5C59F3;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                    display: flex;
                    align-items: center;
                    font-family: 'Eina 03';
                    font-weight: 700;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                }
    
                .footer__links {
                    padding: 2.4rem 0;
                    display: flex;
                    gap: 2rem;
                    align-items: flex-start;
    
                    .footer__btn {
                        height: 2.2rem;
                        color: #838383;
                        font-family: 'DM Sans';
                        font-weight: 400;
                        font-size: 1.8rem;
                        // line-height: 2.8rem;
                        display: flex;
                        align-items: center;
                        border: none;
                        outline: none;
                        padding-right: 1.5rem;
                        background-color: transparent;
                        cursor: pointer;
                    }
                }
            }
        }
}

@media (min-width: 1040px) {
    .footer {
            width: 109.3rem;
            height: 6.8rem;
            margin: 0 auto;
    
            .footerwrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: none;
                order: 4;
                flex-grow: 0;
    
                .avi__logo {
                    padding: 2.3rem 0;
                    color: #5C59F3;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                    display: flex;
                    align-items: center;
                    font-family: 'Eina 03';
                    font-weight: 700;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                }
    
                .footer__links {
                    padding: 2.4rem 0;
                    display: flex;
                    gap: 2rem;
                    align-items: flex-start;
    
                    .footer__btn {
                        height: 2.2rem;
                        color: #838383;
                        font-family: 'DM Sans';
                        font-weight: 400;
                        font-size: 1.8rem;
                        // line-height: 2.8rem;
                        display: flex;
                        align-items: center;
                        border: none;
                        outline: none;
                        padding-right: 1.5rem;
                        background-color: transparent;
                        cursor: pointer;
                    }
                }
            }
        }
}
