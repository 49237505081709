@media (max-width: 319px) {
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 3.2rem 0 0 0;

    .layers {
      display: flex;
      flex-direction: column;
      width: 90%;
      gap: 2.7rem;
      margin: 0 auto;

      .layer__one,
      .layer__two {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2.4rem;
        margin: 0 auto;
      }

      .layer__child {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        .desc {
          font-family: 'DM Sans';
          font-weight: 500;
          font-size: 1.5rem;
          font-style: normal;
          color: #000000;
          display: flex;
          align-items: center;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
    }

    .image__steps {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .stepslayer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        align-items: center;

        .steps__heading {
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .heading__box {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            .heading__title {
              font-family: 'DM Sans';
              font-weight: 500;
              font-size: 2.3rem;
              line-height: 3.2rem;
              text-align: center;
            }
          }

          .hidden__boxes {
            width: 100%;
            height: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2.4rem;

            .hidden {
              width: 100%;
              height: 3.2rem;
              display: flex;
              align-items: center;
              gap: 1.2rem;
            }
          }
        }
      }

      .stepsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;

        .image__box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .vector {
            display: none;
          }

          img {
            scale: 0.75;
          }
        }

        .steps {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 4rem;

          .steps__child {
            display: flex;
            flex-direction: column;
            gap: 4rem;

            .single__step {
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: 1.2rem;
              align-items: center;

              .top {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;

                .span {
                  font-family: 'Cerebri Sans';
                  font-size: 1.8rem;
                  text-transform: uppercase;
                  color: #bbbbbb;
                  line-height: 2.1rem;
                }

                .stepNumber {
                  font-family: 'DM Sans';
                  font-weight: 400;
                  font-size: 3.8rem;
                  color: #5b9dff;
                }
              }

              .bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.8rem;

                .title {
                  font-family: 'DM Sans';
                  font-weight: 500;
                  font-size: 1.8rem;
                  text-align: center;
                  color: #041a29;
                  font-style: normal;
                  line-height: 2.3rem;
                  align-self: stretch;
                }

                .content {
                  width: 80%;
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  font-family: 'DM Sans';
                  font-size: 1.4rem;
                  color: #4a4a4a;
                  font-weight: 400;
                  line-height: 136%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .join__container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 16.1rem;

      .join__box {
        width: 100%;
        padding: 0.3rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        .join__top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 2rem;
          margin: 0 auto;

          .join__span {
            width: 100%;

            .join__text {
              font-family: 'DM Sans';
              font-size: 3.2rem;
              font-weight: 500;
              text-align: center;
              color: #282830;
            }
          }

          .join__question {
            width: 80%;

            .question__text {
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              color: #464754;
            }
          }

          .sendApplication {
            width: 90%;
            height: 6rem;
            padding: 1rem 0;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto;
            justify-content: center;

            .sendbtn {
              width: 100%;
              // height: 2.4rem;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              border: none;
              outline: none;
              cursor: pointer;
              padding: 0.8rem 0;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              margin: 0 auto;
            }
          }
        }

        .join__bottom {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          .accordionWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 3.2rem 0 0 0;

    .layers {
      display: flex;
      flex-direction: column;
      width: 90%;
      gap: 2.7rem;
      margin: 0 auto;

      .layer__one,
      .layer__two {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2.4rem;
        margin: 0 auto;
      }

      .layer__child {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        .desc {
          font-family: 'DM Sans';
          font-weight: 500;
          font-size: 1.5rem;
          font-style: normal;
          color: #000000;
          display: flex;
          align-items: center;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
    }

    .image__steps {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .stepslayer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        align-items: center;

        .steps__heading {
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .heading__box {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            .heading__title {
              font-family: 'DM Sans';
              font-weight: 500;
              font-size: 2.3rem;
              line-height: 3.2rem;
              text-align: center;
            }
          }

          .hidden__boxes {
            width: 100%;
            height: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2.4rem;

            .hidden {
              width: 100%;
              height: 3.2rem;
              display: flex;
              align-items: center;
              gap: 1.2rem;
            }
          }
        }
      }

      .stepsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;

        .image__box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .vector {
            display: none;
          }

          img {
            scale: 0.75;
          }
        }

        .steps {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 4rem;

          .steps__child {
            display: flex;
            flex-direction: column;
            gap: 4rem;

            .single__step {
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: 1.2rem;
              align-items: center;

              .top {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;

                .span {
                  font-family: 'Cerebri Sans';
                  font-size: 1.8rem;
                  text-transform: uppercase;
                  color: #bbbbbb;
                  line-height: 2.1rem;
                }

                .stepNumber {
                  font-family: 'DM Sans';
                  font-weight: 400;
                  font-size: 3.8rem;
                  color: #5b9dff;
                }
              }

              .bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.8rem;

                .title {
                  font-family: 'DM Sans';
                  font-weight: 500;
                  font-size: 1.8rem;
                  text-align: center;
                  color: #041a29;
                  font-style: normal;
                  line-height: 2.3rem;
                  align-self: stretch;
                }

                .content {
                  width: 80%;
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  font-family: 'DM Sans';
                  font-size: 1.4rem;
                  color: #4a4a4a;
                  font-weight: 400;
                  line-height: 136%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .join__container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 16.1rem;

      .join__box {
        width: 100%;
        padding: 0.3rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        .join__top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 2rem;
          margin: 0 auto;

          .join__span {
            width: 100%;

            .join__text {
              font-family: 'DM Sans';
              font-size: 3.2rem;
              font-weight: 500;
              text-align: center;
              color: #282830;
            }
          }

          .join__question {
            width: 80%;

            .question__text {
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              color: #464754;
            }
          }

          .sendApplication {
            width: 90%;
            height: 6rem;
            padding: 1rem 0;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto;
            justify-content: center;

            .sendbtn {
              width: 100%;
              // height: 2.4rem;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              border: none;
              outline: none;
              cursor: pointer;
              padding: 0.8rem 0;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              margin: 0 auto;
            }
          }
        }

        .join__bottom {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          .accordionWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 720px) {
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 3.2rem 0 0 0;

    .layers {
      display: flex;
      flex-direction: column;
      width: 90%;
      gap: 2.7rem;
      margin: 0 auto;

      .layer__one,
      .layer__two {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2.4rem;
        margin: 0 auto;
      }

      .layer__child {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        .desc {
          font-family: 'DM Sans';
          font-weight: 500;
          font-size: 1.5rem;
          font-style: normal;
          color: #000000;
          display: flex;
          align-items: center;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
    }

    .image__steps {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .stepslayer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        align-items: center;

        .steps__heading {
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .heading__box {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            .heading__title {
              font-family: 'DM Sans';
              font-weight: 500;
              font-size: 2.3rem;
              line-height: 3.2rem;
              text-align: center;
            }
          }

          .hidden__boxes {
            width: 100%;
            height: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2.4rem;

            .hidden {
              width: 100%;
              height: 3.2rem;
              display: flex;
              align-items: center;
              gap: 1.2rem;
            }
          }
        }
      }

      .stepsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 3rem;

        .image__box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .vector {
            display: none;
          }

          img {
            scale: 1;
          }
        }

        .steps {
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 4rem;

          .steps__child {
            display: flex;
            flex-direction: column;
            gap: 4rem;

            .single__step {
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: 1.2rem;
              align-items: center;

              .top {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;

                .span {
                  font-family: 'Cerebri Sans';
                  font-size: 1.8rem;
                  text-transform: uppercase;
                  color: #bbbbbb;
                  line-height: 2.1rem;
                }

                .stepNumber {
                  font-family: 'DM Sans';
                  font-weight: 400;
                  font-size: 3.8rem;
                  color: #5b9dff;
                }
              }

              .bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.8rem;

                .title {
                  font-family: 'DM Sans';
                  font-weight: 500;
                  font-size: 1.8rem;
                  text-align: center;
                  color: #041a29;
                  font-style: normal;
                  line-height: 2.3rem;
                  align-self: stretch;
                }

                .content {
                  width: 80%;
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  font-family: 'DM Sans';
                  font-size: 1.4rem;
                  color: #4a4a4a;
                  font-weight: 400;
                  line-height: 136%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .join__container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 16.1rem;

      .join__box {
        width: 100%;
        padding: 0.3rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        .join__top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 2rem;
          margin: 0 auto;

          .join__span {
            width: 100%;

            .join__text {
              font-family: 'DM Sans';
              font-size: 3.2rem;
              font-weight: 500;
              text-align: center;
              color: #282830;
            }
          }

          .join__question {
            width: 80%;

            .question__text {
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              color: #464754;
            }
          }

          .sendApplication {
            width: 90%;
            height: 6rem;
            padding: 1rem 0;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto;
            justify-content: center;

            .sendbtn {
              width: 100%;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              border: none;
              outline: none;
              cursor: pointer;
              padding: 0.8rem 0;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              margin: 0 auto;
            }
          }
        }

        .join__bottom {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          .accordionWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (min-width: 721px) and (max-width: 1039px) {
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 3.2rem;
    gap: 12rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .layers {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 2.7rem;

      .layer__one,
      .layer__two {
        width: 100%;
        height: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 2.4rem;
      }

      .layer__child {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        .desc {
          font-family: 'DM Sans';
          font-weight: 500;
          font-size: 1.5rem;
          font-style: normal;
          color: #000000;
          display: flex;
          align-items: center;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
    }

    .image__steps {
      width: 96.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4rem;

      .stepslayer {
        width: 98.3rem;
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        align-items: center;

        .steps__heading {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .heading__box {
            display: flex;
            align-items: center;
            width: 71.8rem;
            justify-content: center;

            .heading__title {
              font-family: 'DM Sans';
              font-weight: 500;
              font-size: 2.3rem;
              line-height: 3.2rem;
              text-align: center;
            }
          }

          .hidden__boxes {
            width: 108.1rem;
            height: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2.4rem;

            .hidden {
              width: 23.1rem;
              height: 3.2rem;
              display: flex;
              align-items: center;
              gap: 1.2rem;
            }
          }
        }
      }

      .stepsContainer {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        align-items: center;
        justify-content: center;
        gap: 3rem;

        .image__box {
          position: relative;
          width: 39.3rem;

          .vector {
            width: 39.3rem;
            height: 33.2rem;
            background: radial-gradient(
              83.28% 88.75% at 65.99% 11.25%,
              rgba(34, 227, 158, 0.12) 0%,
              rgba(34, 227, 158, 0) 100%
            );
            position: absolute;
          }
        }

        .steps {
          width: 56rem;
          height: 36.4rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 4rem;

          .steps__child {
            height: 16.2rem;
            display: flex;
            align-items: flex-start;
            gap: 4rem;

            .single__step {
              width: 27.8rem;
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: 1.2rem;
              align-items: center;

              .top {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;

                .span {
                  font-family: 'Cerebri Sans';
                  font-size: 1.8rem;
                  text-transform: uppercase;
                  color: #bbbbbb;
                  line-height: 2.1rem;
                }

                .stepNumber {
                  font-family: 'DM Sans';
                  font-weight: 400;
                  font-size: 3.8rem;
                  color: #5b9dff;
                }
              }

              .bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.8rem;

                .title {
                  font-family: 'DM Sans';
                  font-weight: 500;
                  font-size: 1.8rem;
                  text-align: center;
                  color: #041a29;
                  font-style: normal;
                  line-height: 2.3rem;
                  align-self: stretch;
                }

                .content {
                  width: 27.8rem;
                  height: 3.9rem;
                  font-family: 'DM Sans';
                  font-size: 1.4rem;
                  color: #4a4a4a;
                  font-weight: 400;
                  line-height: 136%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .join__container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 16.1rem;

      .join__box {
        width: 100%;
        padding: 0.3rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        .join__top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 41.7rem;
          height: 21.2rem;
          gap: 2rem;

          .join__span {
            width: 39.17rem;
            height: 4rem;

            .join__text {
              font-family: 'DM Sans';
              font-size: 3.2rem;
              font-weight: 500;
              text-align: center;
              color: #282830;
            }
          }

          .join__question {
            width: 41.7rem;
            height: 7.2rem;

            .question__text {
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              color: #464754;
            }
          }

          .sendApplication {
            width: 25.4rem;
            height: 6rem;
            padding: 1rem 0;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto;
            justify-content: center;

            .sendbtn {
              width: 21.4rem;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              border: none;
              outline: none;
              cursor: pointer;
              padding: 0.8rem 1rem;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              margin: 0 auto;
            }
          }
        }

        .join__bottom {
          width: 69.6rem;
          height: 30.8rem;
          padding: 1rem;
          gap: 1rem;

          .accordionWrapper {
            width: 69.1rem;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (min-width: 1040px) {
  .main {
    width: 108.4rem;
    display: flex;
    flex-direction: column;
    padding-top: 3.2rem;
    gap: 12rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .layers {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 2.7rem;

      .layer__one,
      .layer__two {
        width: 100%;
        height: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 2.4rem;
      }

      .layer__child {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        .desc {
          font-family: 'DM Sans';
          font-weight: 500;
          font-size: 1.5rem;
          font-style: normal;
          color: #000000;
          display: flex;
          align-items: center;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
    }

    .image__steps {
      width: 96.3rem;
      // height: 48.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .stepslayer {
        width: 98.3rem;
        // height: 48.4rem;
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        align-items: center;

        .steps__heading {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .heading__box {
            display: flex;
            align-items: center;
            width: 71.8rem;
            height: 6.8rem;
            justify-content: center;

            .heading__title {
              font-family: 'DM Sans';
              font-weight: 500;
              font-size: 2.3rem;
              line-height: 3.2rem;
              text-align: center;
            }
          }

          .hidden__boxes {
            width: 108.1rem;
            height: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2.4rem;

            .hidden {
              width: 23.1rem;
              height: 3.2rem;
              display: flex;
              align-items: center;
              gap: 1.2rem;
            }
          }
        }
      }

      .stepsContainer {
        display: flex;
        width: 98.3rem;
        padding: 1rem;
        gap: 1rem;

        .image__box {
          position: relative;
          width: 39.3rem;

          .mainvector {
            width: 39.3rem;
            height: 33.2rem;

            background: radial-gradient(
              98.37% 102.46% at 65.99% 11.25%,
              rgba(34, 227, 158, 0.12) 0%,
              rgba(34, 227, 158, 0) 100%
            );
            position: absolute;
            left: 1.02%;
            right: 59%;
            top: 7.5%;
            bottom: 10.42%;
            border-radius: 50%;
          }
        }

        .steps {
          width: 56rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          align-items: center;
          justify-content: center;
          gap: 4rem;
          margin: 0 0 0 4rem;

          .steps__child {
            height: 16.2rem;
            display: flex;
            align-items: flex-start;
            gap: 4rem;

            .single__step {
              width: 27.8rem;
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: 1.2rem;
              align-items: center;

              .top {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;

                .span {
                  font-family: 'Cerebri Sans';
                  font-size: 1.8rem;
                  text-transform: uppercase;
                  color: #bbbbbb;
                  line-height: 2.1rem;
                }

                .stepNumber {
                  font-family: 'DM Sans';
                  font-weight: 400;
                  font-size: 3.8rem;
                  color: #5b9dff;
                }
              }

              .bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.8rem;

                .title {
                  font-family: 'DM Sans';
                  font-weight: 500;
                  font-size: 1.8rem;
                  text-align: center;
                  color: #041a29;
                  font-style: normal;
                  line-height: 2.3rem;
                  align-self: stretch;
                }

                .content {
                  //   width: 27.8rem;
                  //   height: 3.9rem;
                  font-family: 'DM Sans';
                  font-size: 1.4rem;
                  color: #4a4a4a;
                  font-weight: 400;
                  line-height: 136%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .join__container {
      width: 108.4rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 16.1rem;

      .join__box {
        width: 69.4rem;
        padding: 0.3rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        .join__top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 41.7rem;
          height: 21.2rem;
          gap: 2rem;

          .join__span {
            width: 39.17rem;
            height: 4rem;

            .join__text {
              font-family: 'DM Sans';
              font-size: 3.2rem;
              font-weight: 500;
              text-align: center;
              color: #282830;
            }
          }

          .join__question {
            width: 41.7rem;
            height: 7.2rem;

            .question__text {
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              color: #464754;
            }
          }

          .sendApplication {
            width: 25.4rem;
            height: 6rem;
            padding: 1rem 0;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto;
            justify-content: center;

            .sendbtn {
              width: 21.4rem;
              background-color: #5c59f3;
              border-radius: 0.4rem;
              border: none;
              outline: none;
              cursor: pointer;
              padding: 0.8rem 1rem;
              color: #ffffff;
              font-family: 'DM Sans';
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              margin: 0 auto;
            }
          }
        }

        .join__bottom {
          width: 69.6rem;
          padding: 1rem;
          gap: 1rem;

          .accordionWrapper {
            width: 69.1rem;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            justify-content: center;
          }
        }
      }
    }
  }
}
