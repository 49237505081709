@media (max-width: 319px) {
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin: 0 auto;

    .headerwrapper {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      .header-_inner__one {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .headertext__container {
          width: 100%;
          height: 50%;
          .headertext,
          .coloredtext {
            font-family: 'DM Sans';
            font-weight: 700;
            font-size: 4rem;
            line-height: 4.8rem;
          }
        }

        .headertext {
          color: #282830;
          padding: 0 2rem;

          .coloredtext {
            color: #5c59f3;
          }
        }

        .headertext__container__two {
          width: 100%;

          .headertext2 {
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #464754;
            padding: 0 2rem;
          }
        }

        .dropdown__therapist {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 2rem;
          margin: 0 auto;

          .wheredropdown {
            width: 90%;
            height: 4rem;
            border-radius: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffff;
            border: 1px solid #e4e7ec;
            gap: 3.6rem;
            // position: relative;

            .dropdownwrapper {
              width: 100%;
              padding: 0.8rem 1rem;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .dropdowntext {
                font-family: 'DM Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: #344054;
              }

              .chevron {
                width: 1.2rem;
                height: 1.2rem;
              }
            }
          }

          .findtherapist {
            width: 90%;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            background-color: #5c59f3;
            border-radius: 0.4rem;
            cursor: pointer;

            .findtherapist__text {
              font-family: 'DM Sans';
              color: #ffffff;
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }

      .header-_inner__two {
        width: 90%;
        margin: 0 auto;
        display: flex;

        .vector {
          display: none;
        }
        .image {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            scale: 0.5;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin: 0 auto;
    padding-top: 5rem;

    .headerwrapper {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      .header-_inner__one {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .headertext__container {
          width: 100%;
          height: 50%;

          .headertext,
          .coloredtext {
            font-family: 'DM Sans';
            font-weight: 700;
            font-size: 4rem;
            line-height: 4.8rem;
          }
        }

        .headertext {
          color: #282830;
          padding: 0 2rem;

          .coloredtext {
            color: #5c59f3;
          }
        }

        .headertext__container__two {
          width: 100%;

          .headertext2 {
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #464754;
            padding: 0 2rem;
          }
        }

        .dropdown__therapist {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          justify-content: center;
          align-items: center;
          position: relative;

          .wheredropdown {
            width: 89%;
            height: 4rem;
            border-radius: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffff;
            border: 1px solid #e4e7ec;
            gap: 3.6rem;
            position: relative;

            .dropdownwrapper {
              width: 100%;
              padding: 0.8rem 1rem;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .dropdowntext {
                font-family: 'DM Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: #344054;
              }

              .chevron {
                width: 1.2rem;
                height: 1.2rem;
              }
            }
          }

          .findtherapist {
            width: 90%;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            background-color: #5c59f3;
            border-radius: 0.4rem;
            cursor: pointer;

            .findtherapist__text {
              font-family: 'DM Sans';
              color: #ffffff;
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }

      .header-_inner__two {
        width: 90%;
        margin: 0 auto;
        display: flex;

        .vector {
          display: none;
        }

        .image {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            // scale: 0.75;

            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 720px) {
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin: 0 auto;
    padding-top: 5rem;

    .headerwrapper {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      gap: 2rem;

      .header-_inner__one {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;

        .headertext__container {
          width: 90%;
          height: 50%;

          .headertext,
          .coloredtext {
            font-family: 'DM Sans';
            font-weight: 700;
            font-size: 4rem;
            line-height: 4.8rem;
          }
        }

        .headertext {
          color: #282830;

          .coloredtext {
            color: #5c59f3;
          }
        }

        .headertext__container__two {
          width: 90%;

          .headertext2 {
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #464754;
          }
        }

        .dropdown__therapist {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2rem;
          margin: 0 auto;

          .wheredropdown {
            width: 100%;
            height: 3.8rem;
            border-radius: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffff;
            border: 1px solid #e4e7ec;
            gap: 3.6rem;

            .dropdownwrapper {
              width: 100%;
              padding: 0.8rem 1rem;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .dropdowntext {
                font-family: 'DM Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: #344054;
              }

              .chevron {
                width: 1.2rem;
                height: 1.2rem;
              }
            }
          }

          .findtherapist {
            width: 100%;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            background-color: #5c59f3;
            border-radius: 0.4rem;
            cursor: pointer;

            .findtherapist__text {
              font-family: 'DM Sans';
              color: #ffffff;
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }

      .header-_inner__two {
        width: 100%;
        margin: 0 auto;
        display: flex;

        .vector {
          display: none;
        }

        .image {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            scale: 0.75;
          }
        }
      }
    }
  }
}

@media (min-width: 721px) and (max-width: 1039px) {
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;

    .headerwrapper {
      width: 90%;
      padding: 1rem;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      .header-_inner__one {
        height: 31.8rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .headertext__container {
          width: 36rem;
          height: 14.6rem;
        }

        .headertext,
        .coloredtext {
          font-family: 'DM Sans';
          font-weight: 700;
          font-size: 4rem;
          line-height: 4.8rem;
        }

        .headertext {
          color: #282830;

          .coloredtext {
            color: #5c59f3;
          }
        }

        .headertext__container__two {
          width: 39.659rem;
          height: 7.2rem;

          .headertext2 {
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #464754;
          }
        }

        .dropdown__therapist {
          width: 51.7rem;
          height: 4rem;
          display: flex;
          align-items: center;
          gap: 1.2rem;

          .wheredropdown {
            width: 23.3rem;
            height: 3.8rem;
            border-radius: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffff;
            border: 1px solid #e4e7ec;
            gap: 3.6rem;

            .dropdownwrapper {
              width: 100%;
              padding: 0.8rem 1rem;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .dropdowntext {
                font-family: 'DM Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: #344054;
              }

              .chevron {
                width: 1.2rem;
                height: 1.2rem;
              }
            }
          }

          .findtherapist {
            width: 14.4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            background-color: #5c59f3;
            border-radius: 0.4rem;
            cursor: pointer;

            .findtherapist__text {
              font-family: 'DM Sans';
              color: #ffffff;
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }

      .header-_inner__two {
        height: 40.8rem;
        display: flex;

        .vector {
          width: 39.3rem;
          height: 37.6rem;
          background: radial-gradient(
            83.28% 88.75% at 65.99% 11.25%,
            rgba(34, 227, 158, 0.12) 0%,
            rgba(34, 227, 158, 0) 100%
          );
          top: 9.81%;
          left: 23.91%;
          display: none;
        }

        .image {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media (min-width: 1040px) {
  .header {
    width: 110rem;
    // height: 42.8rem;
    display: flex;
    align-items: flex-start;
    // padding: 1rem;
    gap: 1rem;
    margin: 0 auto;
    padding-top: 11.2rem;

    .headerwrapper {
      padding: 1rem;
      display: flex;

      .header-_inner__one {
        width: 48.6rem;
        height: 31.8rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .headertext__container {
          width: 36rem;
          height: 14.6rem;
        }

        .headertext,
        .coloredtext {
          font-family: 'DM Sans';
          font-weight: 700;
          font-size: 4rem;
          line-height: 4.8rem;
        }

        .headertext {
          color: #282830;

          .coloredtext {
            color: #5c59f3;
          }
        }

        .headertext__container__two {
          width: 39.659rem;
          height: 7.2rem;

          .headertext2 {
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #464754;
          }
        }

        .dropdown__therapist {
          width: 51.7rem;
          height: 4rem;
          display: flex;
          align-items: center;
          gap: 1.2rem;

          .wheredropdown {
            width: 23.3rem;
            height: 3.8rem;
            border-radius: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffff;
            border: 1px solid #e4e7ec;
            gap: 3.6rem;

            .dropdownwrapper {
              width: 100%;
              padding: 0.8rem 1rem;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .dropdowntext {
                font-family: 'DM Sans';
                font-weight: 400;
                font-style: normal;
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: #344054;
              }

              .chevron {
                width: 1.2rem;
                height: 1.2rem;
              }
            }
          }

          .findtherapist {
            width: 14.4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            background-color: #5c59f3;
            border-radius: 0.4rem;
            cursor: pointer;

            .findtherapist__text {
              font-family: 'DM Sans';
              color: #ffffff;
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }

      .header-_inner__two {
        width: 58.4rem;
        height: 40.8rem;
        position: relative;
        display: flex;

        .vector {
          position: absolute;
          width: 39.3rem;
          height: 37.6rem;
          background: radial-gradient(
            83.28% 88.75% at 65.99% 11.25%,
            rgba(34, 227, 158, 0.12) 0%,
            rgba(34, 227, 158, 0) 100%
          );
          top: 9.81%;
          left: 23.91%;
        }

        .imagespanone {
          width: 247.161px;
          height: 275px;
          flex-shrink: 0;
        }

        .imagespantwo {
          width: 330.436px;
          height: 332px;
          flex-shrink: 0;
        }

        .image {
          display: flex;
          gap: 1.211rem;
        }
      }
    }
  }
}
