@media (max-width: 319px) {
  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .navwrapper {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      .avi__logo {
        color: #5c59f3;
        font-weight: 700;
        font-style: normal;
        font-size: 2.4rem;
        line-height: 2.4rem;
        display: flex;
        align-items: center;
        font-family: 'Eina 03';
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.4rem;
        padding-top: 3.3rem;
        padding-bottom: 1.3rem;
      }

      .nav__links {
        width: 27.809rem;
        height: 5rem;
        display: flex;
        align-items: center;
        gap: 1.8rem;
        padding-top: 2rem;

        .nav__btn {
          width: 8.1rem;
          height: 4rem;
          border-radius: 1.6rem;
          position: relative;
          outline: none;
          border: none;
          background-color: #ffffff;
          cursor: pointer;
          margin: 0.5rem 0;

          .about_span {
            width: 7.8rem;
            height: 1.8rem;
            color: #464754;
            display: flex;
            align-items: center;
            text-align: center;
            position: absolute;
            left: calc(50% - 78px / 2 + 4.72px);
            top: 11px;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .contactwrapper {
          width: 17.909rem;
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .contact__link {
            height: 5rem;
            background-color: #ffffff;
            border: 1px solid #ecf1fc;
            box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
            border-radius: 1.6rem;
            position: absolute;
            width: 15.509rem;
            height: 100%;
            left: 6.7%;
            right: 6.7%;
            top: calc(50% - 50px / 2);

            .contact__span {
              position: absolute;
              width: 12.109rem;
              height: 2.4rem;
              left: calc(50% - 121.09px / 2);
              top: calc(50% - 24px / 2);
              display: flex;
              align-items: center;
              justify-content: center;

              .contact__text {
                font-family: 'Cerebri Sans';
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.4rem;
                display: flex;
                align-items: center;
                text-align: center;
                color: #5c59f3;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .navwrapper {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      .avi__logo {
        color: #5c59f3;
        font-weight: 700;
        font-style: normal;
        font-size: 2.4rem;
        line-height: 2.4rem;
        display: flex;
        align-items: center;
        font-family: 'Eina 03';
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.4rem;
        padding-top: 3.3rem;
        padding-bottom: 1.3rem;
      }

      .nav__links {
        width: 90%;
        // width: 27.809rem;
        justify-content: space-between;
        height: 5rem;
        display: flex;
        align-items: center;
        gap: 1.8rem;
        padding-top: 2rem;

        .nav__btn {
          width: 8.1rem;
          height: 4rem;
          border-radius: 1.6rem;
          position: relative;
          outline: none;
          border: none;
          background-color: #ffffff;
          cursor: pointer;
          margin: 0.5rem 0;

          .about_span {
            width: 7.8rem;
            height: 1.8rem;
            color: #464754;
            display: flex;
            align-items: center;
            text-align: center;
            position: absolute;
            left: calc(50% - 78px / 2 + 4.72px);
            top: 11px;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .contactwrapper {
          width: 17.909rem;
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .contact__link {
            height: 5rem;
            background-color: #ffffff;
            border: 1px solid #ecf1fc;
            box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
            border-radius: 1.6rem;
            position: absolute;
            width: 15.509rem;
            height: 100%;
            left: 6.7%;
            right: 6.7%;
            top: calc(50% - 50px / 2);

            .contact__span {
              position: absolute;
              width: 12.109rem;
              height: 2.4rem;
              left: calc(50% - 121.09px / 2);
              top: calc(50% - 24px / 2);
              display: flex;
              align-items: center;
              justify-content: center;

              .contact__text {
                font-family: 'Cerebri Sans';
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.4rem;
                display: flex;
                align-items: center;
                text-align: center;
                color: #5c59f3;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 481px) and (max-width: 720px) {
  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .navwrapper {
      display: flex;
      width: 90%;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      .avi__logo {
        color: #5c59f3;
        font-weight: 700;
        font-style: normal;
        font-size: 2.4rem;
        line-height: 2.4rem;
        display: flex;
        align-items: center;
        font-family: 'Eina 03';
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.4rem;
        padding-top: 3.3rem;
        padding-bottom: 1.3rem;
      }

      .nav__links {
        width: 27.809rem;
        height: 5rem;
        display: flex;
        align-items: center;
        gap: 1.8rem;
        padding-top: 2rem;

        .nav__btn {
          width: 8.1rem;
          height: 4rem;
          border-radius: 1.6rem;
          position: relative;
          outline: none;
          border: none;
          background-color: #ffffff;
          cursor: pointer;
          margin: 0.5rem 0;

          .about_span {
            width: 7.8rem;
            height: 1.8rem;
            color: #464754;
            display: flex;
            align-items: center;
            text-align: center;
            position: absolute;
            left: calc(50% - 78px / 2 + 4.72px);
            top: 11px;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .contactwrapper {
          width: 17.909rem;
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .contact__link {
            height: 5rem;
            background-color: #ffffff;
            border: 1px solid #ecf1fc;
            box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
            border-radius: 1.6rem;
            position: absolute;
            width: 15.509rem;
            height: 100%;
            left: 6.7%;
            right: 6.7%;
            top: calc(50% - 50px / 2);

            .contact__span {
              position: absolute;
              width: 12.109rem;
              height: 2.4rem;
              left: calc(50% - 121.09px / 2);
              top: calc(50% - 24px / 2);
              display: flex;
              align-items: center;
              justify-content: center;

              .contact__text {
                font-family: 'Cerebri Sans';
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.4rem;
                display: flex;
                align-items: center;
                text-align: center;
                color: #5c59f3;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 721px) and (max-width: 1039px) {
  .nav {
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navwrapper {
      display: flex;
      width: 90%;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      .avi__logo {
        color: #5c59f3;
        font-weight: 700;
        font-style: normal;
        font-size: 2.4rem;
        line-height: 2.4rem;
        display: flex;
        align-items: center;
        font-family: 'Eina 03';
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.4rem;
        padding-top: 3.3rem;
        padding-bottom: 1.3rem;
      }

      .nav__links {
        width: 27.809rem;
        height: 5rem;
        display: flex;
        align-items: center;
        gap: 1.8rem;
        padding-top: 2rem;

        .nav__btn {
          width: 8.1rem;
          height: 4rem;
          border-radius: 1.6rem;
          position: relative;
          outline: none;
          border: none;
          background-color: #ffffff;
          cursor: pointer;
          margin: 0.5rem 0;

          .about_span {
            width: 7.8rem;
            height: 1.8rem;
            color: #464754;
            display: flex;
            align-items: center;
            text-align: center;
            position: absolute;
            left: calc(50% - 78px / 2 + 4.72px);
            top: 11px;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .contactwrapper {
          width: 17.909rem;
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .contact__link {
            height: 5rem;
            background-color: #ffffff;
            border: 1px solid #ecf1fc;
            box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
            border-radius: 1.6rem;
            position: absolute;
            width: 15.509rem;
            height: 100%;
            left: 6.7%;
            right: 6.7%;
            top: calc(50% - 50px / 2);

            .contact__span {
              position: absolute;
              width: 12.109rem;
              height: 2.4rem;
              left: calc(50% - 121.09px / 2);
              top: calc(50% - 24px / 2);
              display: flex;
              align-items: center;
              justify-content: center;

              .contact__text {
                font-family: 'Cerebri Sans';
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.4rem;
                display: flex;
                align-items: center;
                text-align: center;
                color: #5c59f3;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1040px) {
  .nav {
    width: 108rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .navwrapper {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      .avi__logo {
        color: #5c59f3;
        font-weight: 700;
        font-style: normal;
        font-size: 2.4rem;
        line-height: 2.4rem;
        display: flex;
        align-items: center;
        font-family: 'Eina 03';
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.4rem;
        padding-top: 2rem;
      }

      .nav__links {
        width: 27.809rem;
        height: 5rem;
        display: flex;
        align-items: center;
        gap: 1.8rem;
        padding-top: 2rem;

        .nav__btn {
          width: 8.1rem;
          height: 4rem;
          border-radius: 1.6rem;
          position: relative;
          outline: none;
          border: none;
          background-color: #ffffff;
          cursor: pointer;
          margin: 0.5rem 0;

          .about_span {
            width: 7.8rem;
            height: 1.8rem;
            color: #464754;
            display: flex;
            align-items: center;
            text-align: center;
            position: absolute;
            left: calc(50% - 78px / 2 + 4.72px);
            top: 11px;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .contactwrapper {
          width: 17.909rem;
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .contact__link {
            height: 5rem;
            background-color: #ffffff;
            border: 1px solid #ecf1fc;
            box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
            border-radius: 1.6rem;
            position: absolute;
            width: 15.509rem;
            height: 100%;
            left: 6.7%;
            right: 6.7%;
            top: calc(50% - 50px / 2);

            .contact__span {
              position: absolute;
              width: 12.109rem;
              height: 2.4rem;
              left: calc(50% - 121.09px / 2);
              top: calc(50% - 24px / 2);
              display: flex;
              align-items: center;
              justify-content: center;

              .contact__text {
                font-family: 'Cerebri Sans';
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.4rem;
                display: flex;
                align-items: center;
                text-align: center;
                color: #5c59f3;
              }
            }
          }
        }
      }
    }
  }
}
