@media (max-width: 319px) {
  .appointmentsection {
    width: 100%;
    display: flex;
    flex-direction: column;

    .Appointmentmain {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1.4rem;
      gap: 2.4rem;
      width: 100%;

      .main__heading {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .heading {
          font-family: 'DM Sans';
          font-weight: 700;
          font-size: 4rem;
          line-height: 5.5rem;
          text-align: center;
          display: flex;
          align-items: center;
          color: #282830;
        }
      }

      .therapist__form {
        display: flex;
        flex-direction: column;

        .therapistframe {
          display: flex;
          flex-direction: column;
          gap: 2.4rem;
          align-items: center;
          margin: 0 auto;
          justify-content: center;

          .therapistwrapper {
            display: flex;
            flex-direction: column;
            border-radius: 2rem;
            background-color: #f6f6f6;
            margin: 0 auto;
            align-items: center;
            gap: 2.4rem;

            .name__details {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              align-items: center;
              margin-top: 0.493rem;

              .name {
                font-family: 'DM Sans';
                font-weight: 700;
                font-size: 2rem;
                line-height: 3.2rem;
                text-align: center;
                color: #343434;
              }

              .title {
                font-family: 'DM Sans';
                font-weight: 400;
                font-size: 1.6rem;
                text-align: center;
                color: #838383;
              }
            }

            .desc {
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              color: #464754;
              line-height: 2.4rem;
              text-align: center;
            }

            .groupImage {
              scale: 0.5;
            }
          }
        }

        .formImage {
          width: 100%;
          height: 100%;
          display: none;

          .appointmentform {
            scale: 0.25;
          }
        }
      }
    }

    .footer {
      margin-top: 4rem;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .appointmentsection {
    width: 100%;
    display: flex;
    flex-direction: column;

    .Appointmentmain {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1.4rem;
      gap: 2.4rem;
      width: 100%;

      .main__heading {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .heading {
          font-family: 'DM Sans';
          font-weight: 700;
          font-size: 3.2rem;
          line-height: 5.5rem;
          text-align: center;
          display: flex;
          align-items: center;
          color: #282830;
        }
      }

      .therapist__form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .therapistframe {
          display: flex;
          flex-direction: column;
          gap: 2.4rem;
          align-items: center;
          margin: 0 auto;
          justify-content: center;
          width: 100%;

          .therapistwrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 2rem;
            background-color: #f6f6f6;
            margin: 0 auto;
            align-items: center;
            gap: 2.4rem;
            padding: 2rem 0;

            .name__details {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              align-items: center;
              margin-top: 0.493rem;

              .name {
                font-family: 'DM Sans';
                font-weight: 700;
                font-size: 2rem;
                line-height: 3.2rem;
                text-align: center;
                color: #343434;
              }

              .title {
                font-family: 'DM Sans';
                font-weight: 400;
                font-size: 1.6rem;
                text-align: center;
                color: #838383;
              }
            }

            .desc {
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              color: #464754;
              line-height: 2.4rem;
              text-align: center;
            }

            .groupImage {
              scale: 1;
            }
          }
        }

        .formImage {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .appointmentform {
            scale: 0.5;
          }
        }
      }
    }

    .footer {
      margin-top: 4rem;
    }
  }
}

@media (min-width: 481px) and (max-width: 720px) {
  .appointmentsection {
    width: 100%;
    display: flex;
    flex-direction: column;

    .Appointmentmain {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1.4rem;
      gap: 2.4rem;
      width: 100%;

      .main__heading {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .heading {
          font-family: 'DM Sans';
          font-weight: 700;
          font-size: 4rem;
          line-height: 5.5rem;
          text-align: center;
          display: flex;
          align-items: center;
          color: #282830;
        }
      }

      .therapist__form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .therapistframe {
          display: flex;
          flex-direction: column;
          gap: 2.4rem;
          align-items: center;
          margin: 0 auto;
          justify-content: center;
          width: 100%;

          .therapistwrapper {
            width: 70%;
            display: flex;
            flex-direction: column;
            border-radius: 2rem;
            background-color: #f6f6f6;
            margin: 0 auto;
            align-items: center;
            gap: 2.4rem;
            padding: 2rem 0;

            .name__details {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              align-items: center;
              margin-top: 0.493rem;

              .name {
                font-family: 'DM Sans';
                font-weight: 700;
                font-size: 2rem;
                line-height: 3.2rem;
                text-align: center;
                color: #343434;
              }

              .title {
                font-family: 'DM Sans';
                font-weight: 400;
                font-size: 1.6rem;
                text-align: center;
                color: #838383;
              }
            }

            .desc {
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              color: #464754;
              line-height: 2.4rem;
              text-align: center;
              padding: 0 2rem;
            }

            .groupImage {
              scale: 1;
            }
          }
        }

        .formImage {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .appointmentform {
            scale: 0.75;
          }
        }
      }
    }

    .footer {
      margin-top: 4rem;
    }
  }
}

@media (min-width: 721px) and (max-width: 1039px) {
  .appointmentsection {
    width: 100%;
    display: flex;
    flex-direction: column;

    .Appointmentmain {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1.4rem;
      gap: 2.4rem;
      width: 100%;

      .main__heading {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .heading {
          font-family: 'DM Sans';
          font-weight: 700;
          font-size: 4rem;
          line-height: 5.5rem;
          text-align: center;
          display: flex;
          align-items: center;
          color: #282830;
        }
      }

      .therapist__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 3rem;

        .therapistframe {
          display: flex;
          flex-direction: column;
          gap: 2.4rem;
          align-items: center;
          margin: 0 auto;
          justify-content: center;
          width: 100%;

          .therapistwrapper {
            width: 70%;
            display: flex;
            flex-direction: column;
            border-radius: 2rem;
            background-color: #f6f6f6;
            margin: 0 auto;
            align-items: center;
            gap: 2.4rem;
            padding: 2rem 0;

            .name__details {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              align-items: center;
              margin-top: 0.493rem;

              .name {
                font-family: 'DM Sans';
                font-weight: 700;
                font-size: 2rem;
                line-height: 3.2rem;
                text-align: center;
                color: #343434;
              }

              .title {
                font-family: 'DM Sans';
                font-weight: 400;
                font-size: 1.6rem;
                text-align: center;
                color: #838383;
              }
            }

            .desc {
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              color: #464754;
              line-height: 2.4rem;
              text-align: center;
              padding: 0 2rem;
            }

            .groupImage {
              scale: 1;
            }
          }
        }

        .formImage {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .appointmentform {
            scale: 1;
          }
        }
      }
    }

    .footer {
      margin-top: 4rem;
    }
  }
}

@media (min-width: 1040px) {
  .appointmentsection {
    width: 100%;
    display: flex;
    flex-direction: column;

    .navigation {
      width: calc(100% - 40px);
      height: 11.2rem;
      margin: 0 auto;
      position: relative;

      .logoContainer {
        position: absolute;

        .svg {
          width: 11.9rem;
          height: 3rem;
          position: absolute;
          left: 0.04%;
          right: 91.46%;
          top: calc(50% - 30px / 2);
        }

        .avilogo {
          width: 12.1rem;
          height: 2.4rem;
          position: absolute;
          left: 15.85rem;
          top: -3.3rem;
          display: flex;
          align-items: center;

          .avititle {
            font-family: 'Eina 03';
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 2.4rem;
            color: #5c59f3;
          }
        }
      }

      .nav__links__box {
        width: 110rem;
        height: 5rem;
        display: flex;
        align-items: center;
        position: absolute;
        left: 22.39%;
        right: -1.61%;
        top: calc(50% - 50px / 2 - 14px);

        .nav__links {
          display: flex;
          align-items: center;
          width: 27.809rem;
          height: 100%;
          gap: 1.8rem;
          position: absolute;
          left: 57.784rem;
          top: 0;

          .abtbtn {
            width: 8.1rem;
            height: 4rem;
            border-radius: 1.6rem;
            border: none;
            outline: none;
            background-color: #ffffff;
            cursor: pointer;
            color: #464754;
            font-family: 'Cerebri Sans';
            font-weight: 500;
            font-size: 1.6rem;
            font-style: normal;
            text-align: center;
            display: flex;
            align-items: center;
          }

          .contactdiv {
            width: 17.909rem;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .contactlink {
              width: 15.509rem;
              height: 5rem;
              border-radius: 1.6rem;
              box-shadow: 0px 1px 4px rgba(70, 71, 84, 0.06);
              background-color: #ffffff;
              border: 1px solid #ecf1fc;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #5c59f3;
              font-family: 'Cerebri Sans';
              font-size: 1.8rem;
              font-weight: 500;
              line-height: 2.4rem;
              text-align: center;
            }
          }
        }
      }
    }

    .Appointmentmain {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1.4rem;
      gap: 2.4rem;
      width: 100%;

      .main__heading {
        width: 58.1rem;
        height: 7.2rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .heading {
          font-family: 'DM Sans';
          font-weight: 700;
          font-size: 4rem;
          line-height: 5.5rem;
          text-align: center;
          display: flex;
          align-items: center;
          color: #282830;
        }
      }

      .therapist__form {
        display: flex;
        flex-direction: column;
        padding-left: 14rem;
        padding-right: 17.8rem;
        display: grid;
        grid-template-columns: 40.968rem 69.5rem;
        column-gap: 1.732rem;

        .therapistframe {
          width: 38.968rem;
          height: 68.9rem;
          display: flex;
          flex-direction: column;
          gap: 2.4rem;
          align-items: flex-start;
          align-items: center;
          margin: 0 auto;
          justify-content: center;

          .therapistwrapper {
            height: 68.9rem;
            padding: 2.4rem;
            display: flex;
            flex-direction: column;
            border-radius: 2rem;
            background-color: #f6f6f6;
            margin: 0 auto;
            align-items: center;
            gap: 2.4rem;

            .groupImage {
              object-fit: cover;
            }

            .name__details {
              display: flex;
              flex-direction: column;
              width: 27.864rem;
              height: 6.4rem;
              gap: 0.4rem;
              align-items: center;
              margin-top: 0.493rem;

              .name {
                width: 27.864rem;
                height: 3.2rem;
                font-family: 'DM Sans';
                font-weight: 700;
                font-size: 2rem;
                line-height: 3.2rem;
                text-align: center;
                color: #343434;
              }

              .title {
                width: 23.101rem;
                height: 2.8rem;
                font-family: 'DM Sans';
                font-weight: 400;
                font-size: 1.6rem;
                text-align: center;
                color: #838383;
              }
            }

            .desc {
              width: 23.101rem;
              height: 9.6rem;
              font-family: 'DM Sans';
              font-weight: 400;
              font-size: 1.6rem;
              color: #464754;
              line-height: 2.4rem;
              text-align: center;
            }
          }

          .formImage {
            width: 69.5rem;
            height: 45.9rem;
          }
        }
      }
    }

    .footer {
      margin-top: 4rem;
    }
  }
}
