@media (max-width: 319px) {
  .accordion {
    display: flex;
    flex-direction: column;
    width: 100%;

    .accordion__child {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0.8rem;
      gap: 0.8rem;
      border-radius: 0.4rem;
      background-color: #f9fafb;

      .chevron {
        width: 2rem;
        height: 2rem;
      }

      .accordion__title {
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #374151;
      }
    }

    .accordion__listContainer {
      width: 100%;
      padding: 1rem 1rem 1rem 3.5rem;

      .listcontainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;

        .itemlists {
          list-style-type: disc;
          line-height: 2rem;
          font-family: 'DM Sans';
          font-weight: 400;
          font-style: normal;
          font-size: 1.4rem;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .accordion {
    display: flex;
    flex-direction: column;
    width: 100%;

    .accordion__child {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0.8rem;
      gap: 0.8rem;
      border-radius: 0.4rem;
      background-color: #f9fafb;

      .chevron {
        width: 2rem;
        height: 2rem;
      }

      .accordion__title {
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #374151;
      }
    }

    .accordion__listContainer {
      width: 100%;
      padding: 1rem 1rem 1rem 0;
      // padding: 1rem 1rem 1rem 3.5rem;

      .listcontainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;

        .itemlists {
          list-style-type: disc;
          line-height: 2rem;
          font-family: 'DM Sans';
          font-weight: 400;
          font-style: normal;
          font-size: 1.4rem;
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 720px) {
  .accordion {
    display: flex;
    flex-direction: column;
    width: 100%;

    .accordion__child {
      width: 90%;
      display: flex;
      align-items: center;
      padding: 0.8rem;
      gap: 0.8rem;
      border-radius: 0.4rem;
      background-color: #f9fafb;
      margin: 0 auto;

      .chevron {
        width: 2rem;
        height: 2rem;
      }

      .accordion__title {
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #374151;
      }
    }

    .accordion__listContainer {
      width: 90%;
      padding: 1rem 1rem 1rem 5.5rem;

      .listcontainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;

        .itemlists {
          list-style-type: disc;
          line-height: 2rem;
          font-family: 'DM Sans';
          font-weight: 400;
          font-style: normal;
          font-size: 1.4rem;
        }
      }
    }
  }
}

@media (min-width: 721px) and (max-width: 1039px) {
  .accordion {
    display: flex;
    flex-direction: column;

    .accordion__child {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0.8rem;
      gap: 0.8rem;
      border-radius: 0.4rem;
      background-color: #f9fafb;

      .chevron {
        width: 2rem;
        height: 2rem;
      }

      .accordion__title {
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #374151;
      }
    }

    .accordion__listContainer {
      width: 49.3rem;
      padding: 1rem 1rem 1rem 3.5rem;

      .listcontainer {
        width: 44.8rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;

        .itemlists {
          list-style-type: disc;
          line-height: 2rem;
          font-family: 'DM Sans';
          font-weight: 400;
          font-style: normal;
          font-size: 1.4rem;
        }
      }
    }
  }
}

@media (min-width: 1040px) {
  .accordion {
    display: flex;
    flex-direction: column;

    .accordion__child {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0.8rem;
      gap: 0.8rem;
      border-radius: 0.4rem;
      background-color: #f9fafb;

      .chevron {
        width: 2rem;
        height: 2rem;
      }

      .accordion__title {
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #374151;
      }
    }

    .accordion__listContainer {
      padding: 1rem 1rem 1rem 3.5rem;

      .listcontainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;

        .itemlists {
          list-style-type: disc;
          line-height: 2rem;
          font-family: 'DM Sans';
          font-weight: 400;
          font-style: normal;
          font-size: 1.4rem;

          .dropdownchild {
            color: #374151;
            font-family: 'DM Sans';
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem;
            list-style: disc;
          }

          li {
            color: #374151;
            font-family: 'DM Sans';
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem;
          }

          ::marker {
            text-align-last: justify;
            text-align: right;
          }
        }
      }
    }
  }
}
