@media (max-width: 319px) {
  .section {
    width: 100%;
    overflow: hidden;

    .emptywrapper {
      width: 100%;
      background-color: #ffffff;
      color: #ffffff;
      position: relative;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;

      .wrapper__inner__one {
        width: 92.7rem;
        height: 5rem;
        position: absolute;
        left: 22.36%;
        right: 11.43%;
        top: calc(50% - 50px / 2 - 14px);
      }

      .wrapper__inner_two {
        width: 140rem;
        height: 11.2rem;
        position: absolute;
        left: 0%;
        right: 0%;
        top: 0px;

        .frame {
          position: absolute;
          width: 11.9rem;
          height: 3rem;
          left: 0.04%;
          right: 91.46%;
          top: calc(50% - 30px / 2);
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .section {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 720px) {
  .section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
  }
}

// @media (min-width: 721px) and (max-width: 1039px) {
//     .section {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-direction: column;
//         margin: 0 auto;
//         overflow: hidden;

//         .emptywrapper {
//                 width: 100%;
//                 height: 11.2rem;
//                 background-color: #ffffff;
//                 color: #ffffff;
//                 position: relative;
//                 margin: 0 auto;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 visibility: hidden;

//                 .wrapper__inner__one {
//                     width: 92.7rem;
//                     height: 5rem;
//                     position: absolute;
//                     left: 22.36%;
//                     right: 11.43%;
//                     top: calc(50% - 50px/2 - 14px);
//                 }

//                 .wrapper__inner_two {
//                     width: 140rem;
//                     height: 11.2rem;
//                     position: absolute;
//                     left: 0%;
//                     right: 0%;
//                     top: 0px;

//                     .frame {
//                         position: absolute;
//                         width: 11.9rem;
//                         height: 3rem;
//                         left: 0.04%;
//                         right: 91.46%;
//                         top: calc(50% - 30px/2);
//                     }
//                 }
//             }
//     }
// }

// @media (min-width: 1040px) {
//     .section {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-direction: column;
//         margin: 0 auto;

//         .emptywrapper {
//                 width: 140rem;
//                 height: 11.2rem;
//                 background-color: #ffffff;
//                 color: #ffffff;
//                 position: relative;
//                 margin: 0 auto;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 visibility: hidden;

//                 .wrapper__inner__one {
//                     width: 92.7rem;
//                     height: 5rem;
//                     position: absolute;
//                     left: 22.36%;
//                     right: 11.43%;
//                     top: calc(50% - 50px/2 - 14px);
//                 }

//                 .wrapper__inner_two {
//                     width: 140rem;
//                     height: 11.2rem;
//                     position: absolute;
//                     left: 0%;
//                     right: 0%;
//                     top: 0px;

//                     .frame {
//                         position: absolute;
//                         width: 11.9rem;
//                         height: 3rem;
//                         left: 0.04%;
//                         right: 91.46%;
//                         top: calc(50% - 30px/2);
//                     }
//                 }
//             }
//     }

// }
